import { EventName } from '@/modules/trevo/types/enum'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ hook }) => {
  hook('vue:error', (error: Error | any): void => {
    if (import.meta.client) {
      track(EventName.Error, {
        errorName: error.name,
        errorMessage: error.message,
        errorCause: error.cause,
      })

      Sentry.captureException(error)

      const { $toast } = useNuxtApp()

      if ((error as Error).message) // Use type assertion to specify the type of 'error'
        $toast.error((error as Error).message) // Use type assertion to specify the type of 'error'
      else
        $toast.error('Ocorreu um erro inesperado. Tente novamente.')
    }
  })
})
